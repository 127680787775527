import { Retrier } from "@twilio/operation-retrier";
import { Logger } from "../logger";
import { Configuration } from "../configuration";
import { Transport } from "./transport";
import { MediaCategory } from "../media";
import { CancellablePromise } from "../cancellable-promise";

const log = Logger.scope("Network");

class Network {
  private readonly config: Configuration;
  private readonly transport: Transport;

  constructor(config: Configuration, transport: Transport) {
    this.config = config;
    this.transport = transport;
  }

  private backoffConfig() {
    return Object.assign(
      Configuration.backoffConfigDefault,
      this.config.backoffConfigOverride
    );
  }

  private retryWhenThrottled(): boolean {
    return (
      this.config.retryWhenThrottledOverride ??
      Configuration.retryWhenThrottledDefault ??
      false
    );
  }

  private executeWithRetry(
    request,
    retryWhenThrottled: boolean
  ): CancellablePromise<any> {
    return new CancellablePromise(async (resolve, reject, onCancel) => {
      const retrier = new Retrier(this.backoffConfig());

      const codesToRetryOn = [502, 503, 504];
      if (retryWhenThrottled) {
        codesToRetryOn.push(429);
      }

      onCancel(() => {
        retrier.cancel();
        retrier.removeAllListeners();
      });

      retrier.on("attempt", async () => {
        try {
          const requestPromise = request();

          onCancel(() => {
            requestPromise.cancel();
            retrier.cancel();
            retrier.removeAllListeners();
          });

          const result = await requestPromise;
          retrier.succeeded(result);
        } catch (err) {
          if (codesToRetryOn.indexOf(err.status) > -1) {
            retrier.failed(err);
          } else if (err.message === "Twilsock disconnected") {
            // Ugly hack. We must make a proper exceptions for twilsock
            retrier.failed(err);
          } else {
            // Fatal error
            retrier.removeAllListeners();
            retrier.cancel();
            reject(err);
          }
        }
      });

      retrier.on("succeeded", (result) => {
        resolve(result);
      });
      retrier.on("cancelled", (err) => reject(err));
      retrier.on("failed", (err) => reject(err));

      retrier.start();
    });
  }

  public get(url: string): CancellablePromise<any> {
    return new CancellablePromise(async (resolve, reject, onCancel) => {
      const headers = { "X-Twilio-Token": this.config.token };
      const request = this.executeWithRetry(
        () => this.transport.get(url, headers),
        this.retryWhenThrottled()
      );
      log.trace("sending GET request to ", url, " headers ", headers);

      onCancel(() => request.cancel());

      try {
        const response = await request;
        log.trace("response", response);
        resolve(response);
      } catch (err) {
        log.debug(`get() error ${err}`);
        reject(err);
      }
    });
  }

  public post(
    url: string,
    category: MediaCategory | null,
    media: string | Buffer | Blob | FormData | Record<string, unknown>,
    contentType?: string,
    filename?: string
  ): CancellablePromise<any> {
    const headers = {
      "X-Twilio-Token": this.config.token,
    };

    if (
      (typeof FormData === "undefined" || !(media instanceof FormData)) &&
      contentType
    ) {
      Object.assign(headers, {
        "Content-Type": contentType,
      });
    }

    const fullUrl = new URL(url);
    if (category) {
      fullUrl.searchParams.append("Category", category);
    }
    if (filename) {
      fullUrl.searchParams.append("Filename", filename);
    }

    return new CancellablePromise(async (resolve, reject, onCancel) => {
      const request = this.transport.post(fullUrl.href, headers, media);

      onCancel(() => request.cancel());

      log.trace(`sending POST request to ${url} with headers ${headers}`);
      let response;
      try {
        response = await request;
      } catch (err) {
        // If global["XMLHttpRequest"] is undefined, it means that the code is
        // not being executed in the browser.
        if (
          global["XMLHttpRequest"] === undefined &&
          media instanceof FormData
        ) {
          reject(
            new TypeError(
              "Posting FormData supported only with browser engine's FormData"
            )
          );
          return;
        }
        log.debug(`post() error ${err}`);
        reject(err);
        return;
      }
      log.trace("response", response);
      resolve(response);
    });
  }
}

export { Network };
