import { TransportError } from "./transporterror";
import { CancellablePromise } from "../cancellable-promise";

// eslint-disable-next-line
const XHR = global["XMLHttpRequest"] || require("xmlhttprequest").XMLHttpRequest;

type Headers = { [id: string]: string };

function parseResponseHeaders(headerString: string): Headers {
  if (!headerString) {
    return {};
  }

  return headerString
    .split("\u000d\u000a")
    .map((el) => el.split("\u003a\u0020"))
    .filter((el) => el.length === 2 && el[1].length > 0)
    .reduce((prev, curr) => {
      prev[curr[0]] = curr[1];
      return prev;
    }, {});
}

function extractBody(xhr) {
  const contentType = xhr.getResponseHeader("Content-Type");
  if (
    !contentType ||
    contentType.indexOf("application/json") !== 0 ||
    xhr.responseText.length === 0
  ) {
    return xhr.responseText;
  }

  try {
    return JSON.parse(xhr.responseText);
  } catch (e) {
    return xhr.responseText;
  }
}

function adaptHttpResponse(response: Response) {
  try {
    response.body = JSON.parse(response.body);
  } catch (e) {} // eslint-disable-line no-empty
  return response;
}

interface Response {
  status: any;
  headers: Headers;
  body: any;
}

/**
 * Provides generic network interface
 */
class Transport {
  private static request(
    method: string,
    url: string,
    headers: Headers,
    body?
  ): CancellablePromise<Response> {
    return new CancellablePromise<Response>((resolve, reject, onCancel) => {
      const xhr = new XHR();
      let isCancelled = false;

      onCancel(() => {
        xhr.abort();
        isCancelled = true;
      });

      xhr.open(method, url, true);

      xhr.onreadystatechange = function onreadystatechange() {
        if (xhr.readyState !== 4 || isCancelled) {
          return;
        }

        const responseHeaders = parseResponseHeaders(
          xhr.getAllResponseHeaders()
        );
        const body = extractBody(xhr);

        if (200 <= xhr.status && xhr.status < 300) {
          resolve({ status: xhr.status, headers: responseHeaders, body });
        } else {
          const status = xhr.statusText ?? "NONE";
          let bodyRepresentation;
          if (typeof body === "string") {
            if (body && body.split("\n", 2).length === 1)
              bodyRepresentation = body;
            else {
              // TODO: RTDSDK-3716: investigate why body is HTML string
              const errorInfo = body
                .replace(/<.*?>/g, "")
                .split(/\r\n/g)
                .filter((str) => str.length)[0]
                ?.split(" ");
              bodyRepresentation =
                errorInfo?.length > 2 ? errorInfo?.slice(1).join(" ") : "";
            }
          } else {
            bodyRepresentation = JSON.stringify(body);
          }
          const message = `${xhr.status}: [${status}] ${bodyRepresentation}`;
          reject(
            new TransportError(
              message,
              xhr.status,
              body,
              status,
              responseHeaders
            )
          );
        }
      };

      for (const headerName in headers) {
        xhr.setRequestHeader(headerName, headers[headerName]);
        if (
          headerName === "Content-Type" &&
          headers[headerName] === "application/json"
        ) {
          body = JSON.stringify(body);
        }
      }

      xhr.send(body);
    });
  }

  /**
   * Make a GET request by given URL
   */
  public get(url: string, headers: Headers): CancellablePromise<Response> {
    return Transport.request("GET", url, headers);
  }

  /**
   * Make a POST request by given URL
   */
  public post(
    url: string,
    headers: Headers,
    body?
  ): CancellablePromise<Response> {
    return Transport.request("POST", url, headers, body);
  }
}

export { Headers, Response, Transport };
