export const config = {
  production: process.env.REACT_APP_PRODUCTION === 'true',
  graphQL: process.env.REACT_APP_GRAPHQL,
  graphQL2022: process.env.REACT_APP_GRAPHQL_2022,
  graphQLChat: process.env.REACT_APP_GRAPHQL_CHAT,
  keycloak: {
    url: process.env.REACT_APP_KEYCLOAK_SERVER,
    realm: process.env.REACT_APP_KEYCLOAK_REALM,
    protocol: 'openid-connect',
    clientId: process.env.REACT_APP_KEYCLOAK_CLIENT,
  },
  quotingUrl: process.env.REACT_APP_QUOTING_URL,
  resultsUrl: process.env.REACT_APP_RESULTS_URL,
  dateFormat: 'MM/DD/YYYY',
  medigapUrl: process.env.REACT_APP_MEDIGAP_URL,
  pdpUrl: process.env.REACT_APP_PDP_URL,
  userFlowUrl: process.env.REACT_APP_USER_FLOW_URL,
  fileUploadUrl: process.env.REACT_APP_FILE_UPLOAD_URL,
  clientFileUploadUrl: process.env.REACT_APP_CLIENT_FILE_UPLOAD_URL,
  cdnUrl: process.env.REACT_APP_CDN_URL,
  aircallUrl: process.env.REACT_APP_AIRCALL_URL,
  missingAppsTagId: process.env.REACT_APP_PRODUCTION === 'true' ? 'db9f39cc-cf3b-4c5e-a73b-e8db9605b11b' : 'ab05f7da-cce2-49cf-9b7e-bef68bf945a3'
}
