import React from "react";
import {ClientViewOutput, useSaveUserProfileMutation} from "../../../enrollment-types";
import {Box, Button, ButtonGroup, Divider, Menu, Typography} from "@mui/material";
import Card from "../../../shared/Card";
import Param from "../../../shared/Param";
import moment from "moment";
import {config} from "../../../config/config";
import {useSnackbar} from "notistack";
import {useCreatePdpQuoteForClientMutation} from "../../../types";
import {client as apolloClient} from "../../../Apollo/ApolloClient";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import MenuItem from "@mui/material/MenuItem/MenuItem";


interface PdpQuoteProps {
  client?: ClientViewOutput
}

const PdpQuote = ({client}: PdpQuoteProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const {enqueueSnackbar} = useSnackbar();
  const [createQuote] = useCreatePdpQuoteForClientMutation({client: apolloClient})
  const [saveProfile] = useSaveUserProfileMutation()

  const create = React.useCallback(() => {
    if (client) {
      createQuote({
        variables: {
          clientId: client.id,
          input: {
            insulinSavings: false,
            zip: client.zip as string,
            countyName: client.countyName as string,
          }
        }
      }).then(res => {
        if (client.profileId && res.data?.createPdpQuoteForClient?.id) {
          return saveProfile({
            variables: {
              data: {
                pdpQuoteId: res.data?.createPdpQuoteForClient?.id,
                profileId: client.profileId
              }
            }
          })
        }
      })
    }
  }, [client]);

  if (!client) {
    return null;
  }

  const quote = client.pdpQuote;

  const handleClose = () => {
    setAnchorEl(null);
  };

  return <Card sx={{flex: 1}}>
    <Typography color={"textPrimary"} variant={"h4"} className={"mb-5"}>Pdp Quote</Typography>
    <div className={"flex-space-between"}>
      {quote?.createdAt && <Param className={'flex-column'} label={"CREATED"} value={moment(quote?.createdAt).format('L H:mm:ss')}/>}
    </div>
    <div className={"flex-space-between"}>
      {!!quote && <Param className={'flex-column'} label={"INSULIN SAVINGS"} value={quote?.insulinSavings ? 'Yes' : 'No'}/>}
    </div>
    <Divider className={'mb-15'} />
    <Box>
      {(!!client && !client?.pdpQuote) && <Button onClick={create}
                                                 className={'mb-10'}
                                                 variant={'contained'}
                                                 color={'primary'}>Create quote</Button>}

      <ButtonGroup disabled={!client?.pdpQuote}>
        <Button href={`${config.quotingUrl}/pdp?clientId=${client.id}`}
                target={'_blank'}
                className={'mb-10'}
                variant={'contained'}
                color={'primary'}>Open quote</Button>
        <Button
          variant={'contained'}
          size={'small'}
          className={'mb-10'}
          onClick={event => setAnchorEl(event.currentTarget)}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem component={'a'} target={'_blank'}
                  href={`${config.pdpUrl}plans/${quote?.zip}/${quote?.countyName}?clientId=${client.id}`}
                  onClick={handleClose} >Old version</MenuItem>
      </Menu>
      <Typography onClick={() => {
        navigator.clipboard.writeText(`${config.quotingUrl}/pdp?clientId=${client.id}`)
        enqueueSnackbar('Copied!', {variant: "info"});
      }} className={'pointer fs-12 underline'} color={'textPrimary'}>Copy link</Typography>
    </Box>
  </Card>;
}

export default PdpQuote;
