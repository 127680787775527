import React, {ChangeEvent} from "react";
import {createStyles, makeStyles} from "@mui/styles";
import {Box, CircularProgress, IconButton, Typography} from "@mui/material";
import {useLazyQuery} from "@apollo/client";
import {gql} from "@apollo/client";
import {useHistory} from "react-router";
import Card from "../shared/Card";
import {DataGrid, GridColDef} from "@mui/x-data-grid";
import useFilesListPage from "./hooks/useFilesListPage";
import {PageableManuallyUploadedPlanFileOutput, PlanYear, QueryManuallyUploadedPlanFilesArgs} from "../types";
import {client} from "../Apollo/ApolloClient";
import CheckIcon from '@mui/icons-material/Check';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import { useSnackbar } from 'notistack';
import clsx from "clsx";
import TextInput from "../shared/TextInput";
import InputAdornment from "@mui/material/InputAdornment";
import CloseIcon from '@mui/icons-material/Close';
import useFilesListSearchTerm from "./hooks/useFilesListSearchTerm";
import useFilesListPageSize from "./hooks/useFilesListPageSize";
import {config} from "../config/config";
import useFilesListPlanYear from "./hooks/useFilesListPlanYear";
import MenuItem from "@mui/material/MenuItem";
import FormSelect from "../shared/FormSelect";

const useStyles = makeStyles(() =>
  createStyles({
    searchInput: {
      minWidth: '380px',
      width: '380px'
    },
    yearSelector: {
      minWidth: '186px'
    },
    input: {
      display: 'none',
    },
  }),
);

const AddDocButton = ({bidId, field, year, exists}: {bidId: string, field: string, year: PlanYear, exists: boolean}) => {
  const classes = useStyles();
  const [showCheck, setShowCheck] = React.useState(exists);
  const [showLoading, setShowLoading] = React.useState(false);
  const {enqueueSnackbar} = useSnackbar();

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formData = new FormData();
    formData.append('file', (e?.target?.files as any)[0]);

    const requestOptions = {
      method: 'POST',
      headers: {
      },
      body: formData
    };

    const url = config.fileUploadUrl + `${year}/${bidId}/${field}`

    setShowLoading(true);
    fetch(url, requestOptions)
      .then(() => setShowCheck(true))
      .catch(() => enqueueSnackbar('Error in uploading file!', {variant: "error"}))
  }

  const id = bidId + field + year;

  if (showCheck) {
    return <CheckIcon style={{color: 'green'}} fontSize={'small'} />
  }

  if (showLoading) {
    return <CircularProgress size={15} />
  }

  return <>
    <input accept="application/pdf,application/vnd.ms-excel" onChange={onChange} className={classes.input} id={id} type="file" />
    <label htmlFor={id}>
      <IconButton component="span">
        <NoteAddIcon fontSize={'small'} />
      </IconButton>
    </label>
  </>
}


const columns: GridColDef[] = [
  { field: 'bidId', headerName: 'Bid Id', width: 150, sortable: false, disableColumnMenu: true},
  { field: 'evidenceOfCoverageDocUrl', headerName: 'Evidence', align: 'center', headerAlign: 'center', width: 120, sortable: false, disableColumnMenu: true,
    renderCell: params =>
        <AddDocButton exists={!!params.row.evidenceOfCoverageDocUrl} year={params.row.planYear} bidId={params.row.bidId} field={'evidenceOfCoverageDoc'} />
  },
  { field: 'nondiscriminationNoticeDocUrl', headerName: 'Nondiscrimination', align: 'center', headerAlign: 'center', width: 150, sortable: false, disableColumnMenu: true,
    renderCell: params =>
        <AddDocButton exists={!!params.row.nondiscriminationNoticeDocUrl} year={params.row.planYear} bidId={params.row.bidId} field={'nonDiscriminationNoticeDoc'} />
  },
  { field: 'preEnrollmentChecklistDocUrl', headerName: 'Pre Enrollment', align: 'center', headerAlign: 'center', width: 150, sortable: false, disableColumnMenu: true,
    renderCell: params =>
        <AddDocButton exists={!!params.row.preEnrollmentChecklistDocUrl} year={params.row.planYear} bidId={params.row.bidId} field={'preEnrollmentChecklistDoc'} />
  },
  { field: 'starRatingDescDocUrl', headerName: 'Star Rating', align: 'center', headerAlign: 'center', width: 120, sortable: false, disableColumnMenu: true,
    renderCell: params =>
        <AddDocButton exists={!!params.row.starRatingDescDocUrl} year={params.row.planYear} bidId={params.row.bidId} field={'starRatingDescDoc'} />
  },
  { field: 'summaryOfBenefitsUrl', headerName: 'Summary Of Benefits', align: 'center', headerAlign: 'center', width: 170, sortable: false, disableColumnMenu: true,
    renderCell: params =>
        <AddDocButton exists={!!params.row.summaryOfBenefitsUrl} year={params.row.planYear} bidId={params.row.bidId} field={'summaryOfBenefits'} />
  },
  { field: 'planName', headerName: 'Plan Name', align: 'center', headerAlign: 'center', width: 250, sortable: false, disableColumnMenu: true},
  { field: 'orgName', headerName: 'Org Name', align: 'center', headerAlign: 'center', width: 150, sortable: false, disableColumnMenu: true},
  { field: 'parentOrgName', headerName: 'Parent Org Name', align: 'center', headerAlign: 'center', width: 150, sortable: false, disableColumnMenu: true},
  { field: 'zip', headerName: 'Zip', align: 'center', headerAlign: 'center', width: 150, sortable: false, disableColumnMenu: true},
  { field: 'planYear', headerName: 'Year', align: 'center', headerAlign: 'center', width: 100, sortable: false, disableColumnMenu: true},
];

const FilesList = () => {
  const classes = useStyles();
  const [page, setPage] = useFilesListPage();
  const [pageSize, setPageSize] = useFilesListPageSize();
  let history = useHistory();
  const [searchTerm, setSearchTerm] = useFilesListSearchTerm();
  const [planYear, setPlanYear] = useFilesListPlanYear();

  const [searchQuery, {loading, data, refetch}] = useLazyQuery<{manuallyUploadedPlanFiles: PageableManuallyUploadedPlanFileOutput}, QueryManuallyUploadedPlanFilesArgs>(gql(clientsQuery), {
    fetchPolicy: 'no-cache',
    client
  });

  React.useEffect(() => {
    search()
  }, [page, pageSize]);

  React.useEffect(() => {
    onSearch();
  }, [planYear])

  const search = () => {
    searchQuery({
      variables: {
        filter: {
          bidId: searchTerm,
          planYear: planYear ? planYear as PlanYear : null
        },
        page: {
          page: page,
          size: pageSize
        }
      }
    });
  }

  const onSearch = () => {
    if (page > 0) {
      setPage(0);
    } else {
      search();
    }
  }

  const inputProps: any = {
    endAdornment:
      <InputAdornment position="end">
        {!!searchTerm && <CloseIcon cursor={'pointer'} onClick={() => {
          setSearchTerm('');
          setPage(0);
        }} fontSize={'small'} color={'inherit'} />}
      </InputAdornment>,
  };

  return <Card padding={0}>
      <Box display={'flex'} p={'30px'} justifyContent={'space-between'} alignItems={'center'} width={'100%'}>
        <Typography color={'textPrimary'} variant={'h4'}>Plan files list</Typography>
        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
          <TextInput
            className={clsx('mb-0', classes.searchInput)}
            fullWidth
            name="search"
            label="Search BID"
            InputProps={inputProps}
            onKeyPress={event => {
              if (event.key === 'Enter') {
                onSearch();
              }
            }}
            value={searchTerm}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchTerm(e.target.value || '')}
          />
          <FormSelect className={clsx('mb-0 ml-10', classes.yearSelector)}
                      label={'Plan year'}
                      value={planYear}
                      onChange={event => {
                        setPlanYear(event.target.value as string);
                      }}
          >
            <MenuItem value={''}>-</MenuItem>
            <MenuItem value={PlanYear.Year2022}>2022</MenuItem>
            <MenuItem value={PlanYear.Year2023}>2023</MenuItem>
            <MenuItem value={PlanYear.Year2024}>2024</MenuItem>
            <MenuItem value={PlanYear.Year2025}>2025</MenuItem>
          </FormSelect>
        </Box>
      </Box>
      <div style={{ height: '80vh', width: '100%' }}>
        <DataGrid rows={data?.manuallyUploadedPlanFiles?.data ? data?.manuallyUploadedPlanFiles?.data.map(i => ({...i, id: i.bidId})) : []}
                  paginationMode={"server"}
                  sortingMode={'server'}
                  loading={loading}
                  columns={columns}
                  paginationModel={{
                    page,
                    pageSize
                  }}
                  onPaginationModelChange={model => {
                    setPage(model.page);
                    setPageSize(model.pageSize)
                  }}
                  pageSizeOptions={[20, 50, 100]}
                  rowCount={data?.manuallyUploadedPlanFiles?.totalElements}
                  pagination
        />
      </div>
    </Card>;
}

export default FilesList;

const clientsQuery = `
  query ($page: PageInput!, $filter: PlanFileFilterInput) {
    manuallyUploadedPlanFiles(page: $page, filter: $filter) {
      totalPages
      totalElements
      data {
        bidId
        evidenceOfCoverageDocUrl
        nondiscriminationNoticeDocUrl
        preEnrollmentChecklistDocUrl
        starRatingDescDocUrl
        summaryOfBenefitsUrl
        planYear
        orgName
        parentOrgName
        planName
        zip
    }
    }
  }
`
