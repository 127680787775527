import * as log from "loglevel";

function prepareLine(prefix, args) {
  return [`${new Date().toISOString()} MCS Client ${prefix}:`].concat(
    Array.from(args)
  );
}

class Logger {
  private prefix = "";

  private constructor(prefix: string) {
    this.prefix =
      prefix !== null && prefix !== undefined && prefix.length > 0
        ? prefix + " "
        : "";
  }

  static scope(prefix: string): Logger {
    return new Logger(prefix);
  }

  setLevel(level: log.LogLevelDesc): void {
    log.setLevel(level);
  }

  static setLevel(level: log.LogLevelDesc): void {
    log.setLevel(level);
  }

  /* eslint-disable @typescript-eslint/explicit-module-boundary-types */
  trace(...args): void {
    if (log.getLevel() == log.levels.TRACE) {
      log.debug.apply(null, prepareLine(this.prefix + "T", args));
    }
  }
  debug(...args): void {
    log.debug.apply(null, prepareLine(this.prefix + "D", args));
  }
  info(...args): void {
    log.info.apply(null, prepareLine(this.prefix + "I", args));
  }
  warn(...args): void {
    log.warn.apply(null, prepareLine(this.prefix + "W", args));
  }
  error(...args): void {
    log.error.apply(null, prepareLine(this.prefix + "E", args));
  }

  static trace(...args): void {
    if (log.getLevel() == log.levels.TRACE) {
      log.debug.apply(null, prepareLine("T", args));
    }
  }
  static debug(...args): void {
    log.debug.apply(null, prepareLine("D", args));
  }
  static info(...args): void {
    log.info.apply(null, prepareLine("I", args));
  }
  static warn(...args): void {
    log.warn.apply(null, prepareLine("W", args));
  }
  static error(...args): void {
    log.error.apply(null, prepareLine("E", args));
  }
}

export { Logger };
