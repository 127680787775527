import { Network } from "./services/network";
import { Configuration } from "./configuration";
import { CancellablePromise } from "./cancellable-promise";

type MediaCategory = "media" | "body" | "history";

/**
 * Media descriptor on the MCS. Available via REST API only.
 */
interface MediaRecord {
  sid: string;
  serviceSid: string;
  channelSid: string | null;
  messageSid: string | null;
  dateCreated: Date | null;
  dateUploadUpdated: Date | null;
  dateUpdated: Date | null;
  size: number;
  contentType: string;
  filename: string | null;
  category: MediaCategory;
  author: string;
  isMultipartUpstream: boolean;

  url: string;
  contentUrl: string;
  contentDirectUrl: string | null;
}

/**
 * @internal
 * A subset of MediaRecord for Conversation-specific purposes.
 */
interface MediaState {
  sid: string;
  category: MediaCategory;
  filename: string | null;
  contentType: string;
  size: number;
}

interface Links {
  content: string;
  content_direct_temporary?: string;
}

interface MediaResponse {
  sid: string;
  service_sid: string;
  channel_sid: string | null;
  message_sid: string | null;
  date_created?: string;
  date_upload_updated?: string;
  date_updated?: string;
  size: number;
  content_type: string;
  filename?: string;
  category?: MediaCategory;
  author: string;
  is_multipart_upstream?: boolean;
  url: string;
  links: Links;
}

/**
 * @classdesc A Media represents a metadata information for the media upload
 * @property {String} sid - The server-assigned unique identifier for Media
 * @property {String} serviceSid - Service instance id which Media belongs/uploaded to
 * @property {Date} dateCreated - When the Media was created
 * @property {Date} dateUpdated - When the Media was updated
 * @property {Number} size - Size of media, bytes
 * @property {String} contentType - content type of media
 * @property {String} fileName - file name, if present, null otherwise
 * @property {MediaCategory} category - attachment category
 */
class Media {
  private state!: MediaRecord;
  private network: Network;
  private config: Configuration;

  constructor(config: Configuration, network: Network, data: MediaResponse) {
    this.config = config;
    this.network = network;
    this._update(data);
  }

  public get sid(): string {
    return this.state.sid;
  }

  public get serviceSid(): string {
    return this.state.serviceSid;
  }

  public get dateCreated(): Date | null {
    return this.state.dateCreated;
  }

  public get dateUpdated(): Date | null {
    return this.state.dateUpdated;
  }

  public get contentType(): string {
    return this.state.contentType;
  }

  public get size(): number {
    return this.state.size;
  }

  /** @deprecated Use filename instead */
  public get fileName(): string | null {
    return this.state.filename;
  }

  public get filename(): string | null {
    return this.state.filename;
  }

  public get category(): MediaCategory {
    return this.state.category;
  }

  /**
   * Returns direct content URL to uploaded binary. This URL will expire after some time.
   * This function gets a new URL every time, preventing it from expiring but putting additional load on backend.
   * See getCachedContentUrl() for a function that reduces the amount of network requests.
   *
   * It is reasonable to build your own refresh logic upon these two functions: as soon as URL returned
   * by getCachedContentUrl() returns 40x status you should call getContentUrl() to refresh it.
   */
  public getContentUrl(): CancellablePromise<string | null> {
    return new CancellablePromise(async (resolve, reject, onCancel) => {
      const request = this.network.get(`${this.config.mediaUrl}/${this.sid}`);

      onCancel(() => request.cancel());

      try {
        const response = await request;
        this._update(response.body);
        resolve(this.state.contentDirectUrl);
      } catch (e) {
        reject(e);
      }
    });
  }

  private _update(data: MediaResponse): void {
    this.state = {
      sid: data.sid,
      serviceSid: data.service_sid,
      channelSid: data.channel_sid,
      messageSid: data.message_sid,
      dateCreated: data.date_created ? new Date(data.date_created) : null,
      dateUploadUpdated: data.date_upload_updated
        ? new Date(data.date_upload_updated)
        : null,
      dateUpdated: data.date_updated ? new Date(data.date_updated) : null,
      size: data.size,
      contentType: data.content_type,
      author: data.author,
      url: data.url,
      contentUrl: data.links.content,
      contentDirectUrl: data.links.content_direct_temporary ?? null,
      filename: data.filename ?? null,
      category: data.category ?? "media",
      isMultipartUpstream: data.is_multipart_upstream ?? false,
    };
  }

  /**
   * @internal
   * This payload is compatible with Conversations' media object _state().
   */
  _state(): MediaState {
    return {
      sid: this.state.sid,
      category: this.state.category,
      filename: this.state.filename ?? null,
      contentType: this.state.contentType,
      size: this.state.size,
    };
  }
}

export { Media, MediaCategory, MediaResponse, MediaState };
