import { v4 as uuidv4 } from "uuid";

/**
 * Cancellable promise. Extends the functionality of the native Promise to include the cancel method.
 *
 * Example:
 *
 * ```ts
 *
 * const cancellableFetchPromise = new CancellablePromise(async (resolve, reject, onCancel) => {
 *     const request = fetch("https://example.com/");
 *
 *     onCancel(() => request.cancel());
 *
 *     try {
 *         const response = await request;
 *         resolve(response);
 *     } catch (err) {
 *         reject(err);
 *     }
 * });
 *
 * cancellableFetchPromise.cancel();
 * ```
 */
class CancellablePromise<T> extends Promise<T> {
  private readonly id: string;
  private rejectPromise?: (reason?: any) => void;

  private static readonly cancellationMap: Map<string, () => void> = new Map();

  /**
   * Creates a new CancellablePromise.
   * @param executor A callback used to initialize the promise. This callback is passed three arguments:
   * a resolve callback used to resolve the promise with a value or the result of another promise,
   * a reject callback used to reject the promise with a provided reason or error,
   * and an onCancel callback used to define behavior of cancellation.
   */
  constructor(
    executor: (
      resolve: (value: T | PromiseLike<T>) => void,
      reject: (reason?: string | Error) => void,
      onCancel: (cancellationFunction: () => void) => void
    ) => void
  ) {
    const outerId = uuidv4();
    let outerRejectPromise!: (reason?: any) => void;

    super((resolve, reject) => {
      outerRejectPromise = reject;
      return executor(
        (value: T | PromiseLike<T>) => {
          CancellablePromise.cancellationMap.delete(outerId);
          resolve(value);
        },
        (reason?: string | Error) => {
          CancellablePromise.cancellationMap.delete(outerId);
          reject(reason);
        },
        (cancellationFunction: () => void) => {
          CancellablePromise.cancellationMap.set(outerId, cancellationFunction);
        }
      );
    });

    this.id = outerId;
    this.rejectPromise = outerRejectPromise;
  }

  /**
   * Cancels the promise and invokes the cancellation callback if it was defined during instantiation. Cancellation will result in the promise being rejected.
   */
  cancel(): this {
    const onCancel = CancellablePromise.cancellationMap.get(this.id);

    onCancel?.();

    if (this.rejectPromise) {
      this.catch(() => void 0);
      this.rejectPromise(new Error("Promise was cancelled"));
    }

    return this;
  }
}

export { CancellablePromise };
