import log from "loglevel";

export const deprecated = (
  oldName: string,
  newName?: string,
  link?: string
) => {
  return (
    target: unknown,
    propertyKey: string,
    descriptor: PropertyDescriptor
  ): void => {
    if (
      typeof descriptor.value !== "function" &&
      descriptor?.get === undefined
    ) {
      throw new Error(
        "The deprecated decorator can only be applied to methods or getters"
      );
    }

    if (typeof descriptor.value === "function") {
      const originalMethod = descriptor.value;

      descriptor.value = function (...args: unknown[]) {
        deprecationWarning(
          `The method ${oldName} is deprecated${
            newName ? ", use " + newName + " instead" : ""
          }${link ? ", " + link : "."}`
        );
        return originalMethod.apply(this, args);
      };
      return;
    }

    const originalGet = descriptor.get;

    descriptor.get = function () {
      deprecationWarning(
        `The getter ${oldName} is deprecated${
          newName ? ", use " + newName + " instead" : ""
        }${link ? ", " + link : "."}`
      );
      return originalGet?.apply(this);
    };
  };
};

export const deprecationWarning = (warning?: string): void => {
  const currentLevel = log.getLevel();
  log.setLevel("warn");
  log.warn(warning);
  log.setLevel(currentLevel);
};
