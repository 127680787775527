import {
  convertRuleArgument,
  RuleArgument,
  RuleDefinition,
  validateValue,
} from "../type-validator";
import { custom } from "./custom";

export const nonEmptyArray = (
  name: string,
  rules: RuleArgument
): RuleDefinition =>
  custom((value) => {
    if (!Array.isArray(value) || value.length < 1) {
      return [false, `a non-empty array of ${name}`];
    }

    for (const [key, valueOfKey] of value.entries()) {
      const [isValid, received, expected] = validateValue(
        convertRuleArgument(rules),
        valueOfKey
      );

      if (!isValid) {
        return [
          false,
          `a valid non-empty array of ${name} (index ${key} should be ${expected})`,
          `malformed array of ${name} (index ${key} is ${received})`,
        ];
      }
    }

    return [true];
  });
